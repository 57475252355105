@import '../../Style/theme.scss';
@import '../../Style/typographie.scss';

.empty-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 20px;
    height: calc(100% - 135px);
    text-align: center;

    .spacer {
        flex: 1;
    }

    .content {
        flex: 2;
    }
}
