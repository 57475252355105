@import './../../Style/theme.scss';

$MENU_MOBILE_WIDTH_PX: 320px;

.LeftMenu {
    height: 100dvh;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 1000;
    transition: all 0.3s ease-in-out;

    .menu {
        position: absolute;
        top: 0;
        left: 0;

        width: $MENU_MOBILE_WIDTH_PX;
        height: 100dvh;
        background-color: $white;
        opacity: 1 !important;
        overflow: hidden;
    }

    .menu-overlay {
        width: calc(100% - $MENU_MOBILE_WIDTH_PX);
        height: 100%;
        position: absolute;
        right: 0;
        background-color: rgba(27, 29, 30, 0.7);
    }

    &.-is-close {
        width: 0 !important;
        left: -100vw !important;
    }

    &.-is-open {
        left: 0 !important;
    }
}
