@import '../../../Style/theme.scss';
@import '../../../Style/typographie.scss';

.Step2 {
    .text {
        text-align: center;
    }

    .text-headline-medium {
        font-weight: 700;
        margin-bottom: 16px;
    }

    .content-html {
        max-height: 40dvh;
        overflow-y: scroll;

        padding: 20px;
        border: 1px solid #e8e8e8;
        border-radius: 10px;

        > div p {
            color: $element-greys-secondary !important;
            padding-bottom: 30px;
            display: block;
            > strong {
                color: $element-greys-secondary !important;
                font-weight: 700 !important;
            }
        }
    }
}
