@import './theme';
@import './breakpoint';

//https://www.figma.com/file/I8L4Dl1g0r35Oz689IzWht/SpreeAI---Design-System?type=design&node-id=2120-793&mode=design&t=WiiTkCzXP4xMdcju-4

@mixin primary-font-mixin {
    font-family: 'poppins', sans-serif;
}

@mixin secondary-font-mixin {
    font-family: 'montserrat', sans-serif;
}

@mixin display-xlarge-mixin {
    font-size: 64px;
    line-height: 80px;
    font-weight: 500;
    color: $element-greys-primary;

    @include primary-font-mixin();
}
.text-display-xlarge {
    @include display-xlarge-mixin;
}
/* -------------- */

@mixin display-large-mixin {
    font-size: 57px;
    line-height: 64px;
    font-weight: 500;
    color: $element-greys-primary;

    @include primary-font-mixin();
}
.text-display-large {
    @include display-large-mixin;
}
/* -------------- */

@mixin display-medium-mixin {
    font-size: 45px;
    line-height: 52px;
    font-weight: 500;
    color: $element-greys-primary;

    @include primary-font-mixin();
}
.text-display-medium {
    @include display-medium-mixin;
}
/* -------------- */

@mixin display-small-mixin {
    font-size: 36px;
    line-height: 44px;
    font-weight: 500;
    color: $element-greys-primary;

    @include primary-font-mixin();
}
.text-display-small {
    @include display-small-mixin;
}
/* -------------- */

@mixin headline-large-mixin {
    font-size: 32px;
    line-height: 40px;
    font-weight: 500;
    color: $element-greys-primary;

    @include primary-font-mixin();
}
.text-headline-large {
    @include headline-large-mixin;
}
/* -------------- */

@mixin headline-medium-mixin {
    font-size: 28px;
    line-height: 36px;
    font-weight: 500;
    color: $element-greys-primary;

    @include primary-font-mixin();
}

@mixin headline-medium-mixin-alt {
    font-size: 22px;
    font-weight: 600;
    line-height: 32.5px;

    @include primary-font-mixin();
}

/* -------------- */

@mixin headline-small-mixin {
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
    color: $element-greys-primary;

    @include primary-font-mixin();

    @media screen and (max-width: 390px) {
        font-size: 18px;
        line-height: 24px;
    }
}
.text-headline-small {
    @include headline-small-mixin;
}
.text-headline-medium {
    // if px is inferior to 500px
    @media screen and (max-width: $MOBILE_BREAKPOINT) {
        @include headline-small-mixin();
    }
    @media screen and (min-width: $MOBILE_BREAKPOINT) {
        @include headline-medium-mixin();
    }
}
/* -------------- */

@mixin title-large-mixin {
    font-size: 22px;
    line-height: 28px;
    font-weight: 500;
    color: $element-greys-primary;

    @include primary-font-mixin();
}
.text-title-large {
    @include title-large-mixin;
}
.text-title-large-bold {
    @include title-large-mixin;
    font-weight: 700;
}
/* -------------- */

@mixin title-medium-mixin {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: $element-greys-primary;

    @include secondary-font-mixin();
}
.text-title-medium {
    @include title-medium-mixin;
}
.text-title-medium-bold {
    @include title-medium-mixin;
    font-weight: 500;
}
/* -------------- */

@mixin title-small-mixin {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: $element-greys-primary;

    @include secondary-font-mixin();
}
.text-title-small {
    @include title-small-mixin;
}
.text-title-small-bold {
    @include title-small-mixin;
    font-weight: 500;
}
/* -------------- */

@mixin label-large-mixin {
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    color: $element-greys-primary;

    @include secondary-font-mixin();
}
.text-label-large {
    @include label-large-mixin;
}
/* -------------- */

@mixin label-medium-mixin {
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    color: $element-greys-primary;

    @include secondary-font-mixin();
}
.text-label-medium {
    @include label-medium-mixin;
}
/* -------------- */

@mixin label-small-mixin {
    font-size: 11px;
    line-height: 16px;
    font-weight: 700;
    color: $element-greys-primary;

    @include secondary-font-mixin();
}
.text-label-small {
    @include label-small-mixin;
}
/* -------------- */

@mixin body-large-mixin {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: $element-greys-primary;

    @include secondary-font-mixin();
}

/* -------------- */

@mixin body-medium-mixin {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: $element-greys-primary;

    @include secondary-font-mixin();
}
.text-body-medium {
    @include body-medium-mixin;
}
.text-body-medium-bold {
    @include body-medium-mixin;
    font-weight: 500;
}
/* -------------- */

@mixin body-small-mixin {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: $element-greys-primary;

    @include secondary-font-mixin();
}
.text-body-small {
    @include body-small-mixin;
}
.text-body-small-bold {
    @include body-small-mixin;
    font-weight: 500;
}
/* -------------- */

.text-body-large {
    // if px is inferior to 500px
    @media screen and (max-width: $MOBILE_BREAKPOINT) {
        @include body-medium-mixin();
    }
    @media screen and (min-width: $MOBILE_BREAKPOINT) {
        @include body-large-mixin();
    }
}
.text-body-large-bold {
    @include body-large-mixin;
    font-weight: 700;
}

// font colors
.text-element-greys-primary {
    color: $element-greys-primary;
}
.text-surface-grey-primary {
    color: $surface-grey-primary;
}
.text-element-greys-secondary {
    color: $element-greys-secondary;
}
.text-element-brand-rest-primary {
    color: $element-brand-rest-primary;
}
.text-element-greys-tertiary {
    color: $element-greys-tertiary;
}
.text-white {
    color: $white;
}
.text-surface-grey-disabled {
    color: $surface-grey-disabled;
}
.text-surface-support-secondary {
    color: $surface-support-secondary;
}
.text-surface-support-tertiary {
    color: $surface-support-tertiary;
}
.text-surface-grey-tertiary {
    color: $surface-grey-tertiary;
}
.text-surface-error-rest-primary {
    color: $surface-error-rest-primary;
}
.text-surface-brand-rest-primary {
    color: $surface-brand-rest-primary;
}
.text-element-onbrand-primary {
    color: $element-onbrand-primary;
}
.text-surface-error-rest-primary {
    color: $surface-error-rest-primary;
}
