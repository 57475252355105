.LoginPage {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: justify-content;

    .wrapper-back-icon {
        width: fit-content;
        svg {
            cursor: pointer;
        }
    }

    .wrapper-content {
        margin-top: auto;
        margin-bottom: auto;
        width: 100%;

        .wrapper-input {
            display: flex;
            flex-direction: column;
            gap: 20px;

            padding: 40px 0;
        }
    }

    .forget-password {
        margin-top: 25px;
        text-align: center;
        cursor: pointer;
    }

    .PoweredBy {
        position: absolute;
        bottom: 25px;
        left: 0;
        right: 0;
    }
}
