@import './../../../../Style/theme.scss';

.LeftMenu {
    > .menu {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 16px 16px 0px;

        .header {
            border-bottom: 1px solid #d7d9da;
            padding: 0 16px 16px 16px;
            margin-top: 25px;
        }

        .inner {
            display: flex;
            flex-direction: column;

            .tab-menu-item {
                display: flex;
                gap: 16px;
                align-items: center;
                padding: 16px 16px;
            }

            margin-top: 24px;
        }

        .footer {
            margin-bottom: 60px;
            padding: 0 18px;

            .text-body-large {
                text-align: center;
                color: $element-onerror-secondary;

                margin-top: 24px;
                cursor: pointer;
            }
        }
    }
}

.Account {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .header {
        display: flex;
        cursor: pointer;
        align-items: center;
    }

    .back-arrow-container {
        .text-body-medium {
            color: $surface-brand-rest-primary;
        }
    }

    .inner {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .tab-menu-item {
            display: flex;
            gap: 16px;
            align-items: center;
            padding: 28px 7px;
            border-bottom: 1px solid #d7d9da;
            cursor: pointer;
        }
    }

    .footer {
        margin-top: 65px;

        .button-logout {
            text-transform: uppercase;
        }
        .text-body-large {
            text-align: center;
            color: $surface-error-rest-primary;
            margin-top: 23px;
            cursor: pointer;
        }
    }
}
