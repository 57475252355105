@import './../../Style/theme.scss';

.Button {
    border-radius: 10px;
    width: 100%;
    padding: 17px 0;
    cursor: pointer;
    outline: none;
    border: none;

    display: flex;
    justify-content: center;
    gap: 8px;

    span {
        font-weight: 500;
    }

    &.-tonal {
        background-color: $surface-grey-opposite;

        span {
            color: $white;
        }

        &:hover {
            background-color: $surface-grey-opposite-alt;
        }
    }

    &.-primary {
        background-color: $element-brand-rest-primary;

        span {
            color: $white;
        }

        &:hover {
            background-color: $element-brand-overlay-primary;
        }
    }

    &.-outline {
        background-color: $white;
        border: 1px solid $element-greys-primary;

        span {
            color: $element-greys-primary;
        }

        &:hover {
            background-color: #eaedf5;
            border: 1px solid $surface-brand-rest-primary;

            span {
                color: $element-brand-rest-primary;
            }
        }
    }

    &.-white {
        background-color: $white;

        span {
            color: $linear-gradient4;
        }

        &:hover {
            background-color: #eaedf5;
            border: 1px solid $surface-brand-rest-primary;

            span {
                color: $element-brand-rest-primary;
            }
        }
    }

    &.-disabled {
        background-color: #d7d9da;

        &:hover {
            background-color: #d7d9da !important;
        }
    }

    &.-danger {
        background-color: $surface-error-rest-primary;

        span {
            color: $white;
        }

        &:hover {
            background-color: $surface-error-overlay-primary;
        }
    }
}
