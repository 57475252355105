@import './../../../Style/theme.scss';

.AuthLoadingFlowQrCodePage {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;

    span {
        text-align: center;
    }

    .wrapper-qr-code {
        margin-top: 40px;
        background-color: $surface-brand-rest-secondary;
        padding: 40px;
        width: fit-content;
    }

    @media (max-width: 480px) {
        padding: 0 16px;
    }
}