@import "./../../Style/theme.scss";

.GlobalLoading {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-color: white;
    align-items: center;
    justify-content: center;
    z-index: 10000001;
}
