@import '../../../../Style/theme.scss';

.AutHomeGallery {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;

    .item {
        display: flex;
        cursor: pointer;
        justify-content: center;
        img {
            max-width: 100%;
            height: auto;
            border: 1px solid $border-grey;
            border-radius: 10px;
        }
    }
}

.Spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80%;
    width: 100%;
}
