@import "../../Style/theme.scss";

.Radio {
    display: flex;
    justify-content: space-between;

    .item {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        cursor: pointer;
        width: 100%;

        border-bottom: 1px solid $element-greys-tertiary;
        padding-bottom: 10px;

        .label {
            text-align: center;
        }

        &.-not-selected {
            border-bottom: 1px solid $element-greys-tertiary;

            .label {
                color: $element-greys-tertiary;
            }
        }
    }
}
