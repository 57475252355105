@import './../Style/theme.scss';
@import './../Style/breakpoint.scss';

.DefaultLayout {
    max-width: 650px;
    margin: 0 auto;
    background-color: $white;
    height: 100%;
    border-radius: 20px;
    padding: 40px;
    overflow-y: auto;

    @media (max-width: $MOBILE_BREAKPOINT) {
        padding: 20px 16px 16px 16px;
    }

    .close-icon {
        position: absolute;
        right: 27px;
        top: 27px;
        z-index: 2;

        @media (max-width: $MOBILE_BREAKPOINT) {
            top: 26px;
        }
    }

    .back-icon {
        position: absolute;
        left: 44px;
        top: 44px;
    }

    @media (max-width: $MOBILE_BREAKPOINT) {
        border-radius: 0;
    }
}
