.Step3 {
    .wrapper-input {
        display: flex;
        justify-content: center;
        margin-bottom: 40px;
    }

    .resend-code {
        margin-top: 25px;
        text-align: center;
        cursor: pointer;
    }
}
