@import './../../Style/theme.scss';

.AuthHomePage {
    height: 100%;

    > .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 40px;

        .settings {
            display: flex;
            align-items: center;
            cursor: pointer;

            svg {
                &:hover {
                    animation: rotate 3s infinite;
                }

                @keyframes rotate {
                    from {
                        transform: rotate(0deg);
                    }
                    to {
                        transform: rotate(360deg);
                    }
                }
            }
        }
    }

    .download-circle {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 25px;
        border: 1px solid #1b1d1e;
        cursor: pointer;
    }

    .wrapper-content-empty {
        border: 1px solid #959c9d;
        padding: 30.5px 83.5px;
        border-radius: 10px;
        height: 80%;
        margin-top: 40px;

        @media (max-width: 480px) {
            padding: 16px;
        }
    }

    .wrapper-content-full {
        display: flex;
        position: relative;
        flex-direction: column;
        gap: 35px;
        margin-top: 25px;

        .img {
            height: 520px;
            object-fit: scale-down;
            border: 1px solid $border-grey;
            border-radius: 10px;
        }

        .icons {
            position: absolute;
            right: 10px;
            top: 15px;
            display: flex;
            flex-direction: column;
            gap: 12px;
            align-items: center;
            .icon {
                cursor: pointer;
                z-index: 1;
            }
        }
        .camera-icon:hover {
            animation: scale-animation 1s 1;
            fill: $surface-grey-opposite-alt;

            @keyframes scale-animation {
                0% {
                    transform: scale(1);
                }
                100% {
                    transform: scale(1.2);
                }
            }
        }
    }

    .wrapper-content-mobile {
        display: flex;
        flex-direction: column;
        gap: 35px;
        margin-top: 35px;

        .img {
            height: 500px;
            border: 1px solid $border-grey;
            border-radius: 10px;
        }

        .icons {
            position: absolute;
            right: 10%;
            top: 25%;
            display: flex;
            flex-direction: column;
            gap: 12px;
            align-items: center;
            .icon {
                cursor: pointer;
                z-index: 1;
            }
        }
    }

    .spinner {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 90%;
        flex-direction: column;
        gap: 20px;
    }
}
