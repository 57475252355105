@import './../../Style/theme.scss';
@import './../../Style/breakpoint.scss';
@import '../../Style/typographie.scss';

.HomePage {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    margin: -40px; /* Remove the padding of the body so the desktop video can be fullscreen */

    @media (max-width: $MOBILE_BREAKPOINT) {
        justify-content: end;
        margin: 0;
        width: 100%;
    }

    hr {
        color: #c0c0c0;
        width: 100%;
        margin: 25px 0px;
        @media (max-width: $MOBILE_BREAKPOINT) {
            display: none;
        }
    }

    .next-btn {
        max-width: 225px;
        margin: 0 auto 5px;
        background-color: $surface-brand-rest-primary;
        z-index: 2;
        text-transform: uppercase;

        @media (max-width: $MOBILE_BREAKPOINT) {
            min-width: 100%;
            margin: 0;
            border: 2px solid $surface-brand-rest-primary;
            background: rgba(0, 0, 0, 0.5);
            backdrop-filter: blur(12.5px);
        }
    }

    .PoweredBy {
        margin-left: auto;
        margin-right: auto;
        margin-top: 15px;
        margin-bottom: 20px;
        z-index: 2;
        div {
            @media (max-width: $MOBILE_BREAKPOINT) {
                color: $element-greys-white;
            }
        }
    }

    .wrapper-informations {
        margin: 24px 40px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 18px;
        min-height: 100%;
        justify-content: end;

        span {
            text-transform: uppercase;
        }

        @media (max-width: $MOBILE_BREAKPOINT) {
            margin: 0 40px;
        }

        .description {
            text-align: center;

            @media (max-width: $MOBILE_BREAKPOINT) {
                margin-bottom: 0;
                border-bottom: none;
                text-align: left;
                color: $element-greys-white;
                z-index: 2;
            }
        }

        .title {
            text-align: center;
            @media (max-width: $MOBILE_BREAKPOINT) {
                margin: 0 auto;
                text-align: left;
                text-transform: uppercase;
                font-size: 24px;
                line-height: 30px;
                font-weight: 600;
                color: $element-greys-white;
                z-index: 2;
            }
        }
    }

    .wrapper-actions {
        display: flex;
        gap: 35px;

        .home-block {
            padding: 20px 40px;
            border-radius: 10px;
            height: 212px;
            width: 100%;
            text-align: center;

            &.block-qr-code {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-around;
                background-color: $surface-brand-rest-secondary;

                .element {
                    width: 98px;
                    height: 98px;
                    background-color: $white;

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .LoadingSpinner {
                        scale: 0.5;
                    }
                }
            }

            &.block-login {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-around;
                background-color: $surface-grey-disabled;

                .element {
                    margin-top: 40px;
                    width: 100%;
                }
            }
        }

        &.-desktop {
            @media (max-width: ($MOBILE_BREAKPOINT - 1px)) {
                display: none;
            }
        }

        &.-mobile {
            display: flex;
            flex-direction: column;
            align-items: center;
            @media (min-width: $MOBILE_BREAKPOINT) {
                display: none;
            }
        }
    }

    .video-container {
        min-height: 50dvh;
        width: 100%;
        object-fit: cover;
        @media (max-width: $MOBILE_BREAKPOINT) {
            min-height: 100%;
            min-width: 100%;
            z-index: 1;
            object-fit: cover;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}
