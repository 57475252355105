@import './../../Style/theme.scss';

.AuthentificationPage {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
    background-color: #2b2b2b;
    > .top {
        display: flex;
        flex-direction: column;
        background:
            linear-gradient(0deg, $surface-grey-opposite, $surface-grey-opposite),
            radial-gradient(118.71% 52.19% at 0% 0%, #121212 0%, #232323 65.47%, #333333 100%)
                /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;

        padding-bottom: 120px;

        .wrapper-close-icon {
            margin: 27px;
            margin-left: auto;
            cursor: pointer;
        }

        .wrapper-back-icon {
            margin: 16px;
            margin-right: auto;
            cursor: pointer;
        }

        .top-info {
            display: flex;
            flex-direction: column;
            max-width: 450px;
            margin-left: auto;
            margin-right: auto;

            .title {
                margin-bottom: 40px;
            }

            .elements {
                display: flex;
                flex-direction: column;
                gap: 16px;

                .element {
                    display: flex;
                    gap: 10px;
                    align-items: center;
                }
            }

            @media (max-width: 500px) {
                padding-left: 24px;
                padding-right: 24px;
            }
        }
    }

    .box {
        background-color: $white;
        border-radius: 30px 30px 0px 0px;
        margin-top: -30px;
        height: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;

        .content {
            display: flex;
            flex-direction: column;
            width: 65%;
            margin-left: auto;
            margin-right: auto;
            padding-top: 40px;
            padding-bottom: 40px;

            .top {
                display: flex;
                flex-direction: column;
                gap: 15px;
            }

            .middle {
                display: flex;
                align-items: center;
                margin-top: 46px;
                margin-bottom: 16px;

                .line {
                    height: 1px;
                    background-color: $element-greys-tertiary;
                    width: 100%;
                }

                .or {
                    padding: 0 24px;
                }
            }

            .bottom {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 15px;

                .btn-sso {
                    cursor: pointer;
                    width: 300px;
                }

                .apple-button {
                    width: 100%;
                    &:hover {
                        background-color: $element-greys-primary;
                        rect {
                            fill: $element-greys-primary;
                        }
                    }
                }
            }

            @media (max-width: 500px) {
                width: calc(100% - 32px);
            }
        }
    }

    .PoweredBy {
        margin-bottom: 20px;
    }
}
