@import '../../Style/theme.scss';
@import '../../Style/typographie.scss';
@import '../../Style/breakpoint.scss';

.DeleteModal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.delete-overlay {
    background: $surface-grey-background;
    border-radius: 10px;
    box-shadow: 0 25px 60px -10px rgba(28, 39, 49, 0.12);
    height: 50%;
    padding: 24px;
    margin-left: 15px;
    margin-right: 15px;
    position: fixed;
    top: 25%;
    z-index: 1000;
    @media (max-width: $MOBILE_BREAKPOINT) {
        height: 65%;
    }
}

.delete-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
    height: 100%;
}

.delete-texts {
    @include body-large-mixin;
    font-weight: 400;

    & p {
        padding-top: 20px;
    }
}

.delete-buttons {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Centers horizontally */
    align-items: center; /* Centers vertically (optional) */
    & button:first-child {
        width: 80%;
        margin-bottom: 10px;
    }
}
