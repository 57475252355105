@import './../../Style/theme.scss';
@import './../../Style/breakpoint.scss';
@import '../../Style/typographie.scss';

.ForgotPasswordPage {
    display: flex;
    flex-direction: column;
    text-align: center;
    height: 100%;
    justify-content: space-between;

    .stepOneDesc {
        padding: 40px 0;
    }

    .wrapper-input {
        text-align: left;
    }

    .stepTwoTitle {
        padding: 40px 0 20px;
    }

    .stepButton {
        margin-bottom: 40px;
        text-transform: uppercase;
    }
}
