@import '../../Style/theme.scss';

.CtaOverlay {
    position: fixed;
    margin: 0 auto;
    left: -40px;
    right: -40px;
    top: -40px;
    bottom: -40px;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(5px);
    padding: 44px 44px 154px;
    z-index: 10;

    &--hidden {
        display: none;
    }

    &__content {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
    }

    &__ctaText {
        color: $white;
        text-align: center;
        font-family: 'Montserra', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 11px;
    }

    &__arrowIcon {
        margin-bottom: 16px;
    }

    &__ctaButton {
        width: 100px;
        height: 100px;
        background-color: $surface-brand-rest-secondary;
        border: none;
        cursor: pointer;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
    }

    &__ctaButtonCircle {
        width: 66px;
        height: 66px;
        background-color: $surface-brand-rest-primary;
        box-shadow: 0px 4px 6px rgba(22, 106, 71, 0.25);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.3s ease-in-out;
        animation: scaleUpDown 1.5s infinite;

        svg {
            transition: all 0.3s ease-in-out;
        }
    }

    &__ctaButton:hover &__ctaButtonCircle {
        width: 100px;
        height: 100px;
        animation: none;

        svg {
            transform: scale(1.2);
        }
    }

    @keyframes scaleUpDown {
        0%,
        100% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.2);
        }
    }
}
