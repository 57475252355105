.back-arrow-container {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    margin: 20px 0;
    align-items: center;

    span {
        cursor: pointer;
    }

    .back-arrow {
        cursor: pointer;
    }
}
