@import './../../Style/theme.scss';
@import '../../Style/typographie.scss';
.Input {
    display: flex;
    flex-direction: column;
    position: relative;

    label {
        margin-bottom: 4px;
    }

    input {
        border-radius: 10px;
        border: 1px solid var(--Surface-Grey-Tertiary, #d7d9da);
        display: flex;
        padding: 12px 8px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        outline: none !important;
    }

    &.-password {
        .eye {
            position: absolute;
            top: 27px;
            right: 8px;
        }
    }

    &.-error {
        input {
            border: 1px solid $surface-error-rest-primary;
        }

        label {
            color: $surface-error-rest-primary;
        }
    }

    .wrapper-error {
        .text-error {
            color: $surface-error-rest-primary;
        }
    }

    .icon-right {
        cursor: pointer;
        svg {
            position: absolute;
            top: 30px;
            right: 8px;
        }
    }
}
