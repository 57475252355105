@import '../../../../../Style/theme.scss';
@import '../../../../../Style/breakpoint.scss';

.ManageAccount {
    height: 100%;

    .header {
        width: 450px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 35px;
    }

    .back-arrow-container {
        .text-body-medium {
            color: $surface-brand-rest-primary;
        }
    }

    form {
        display: flex;
        flex-direction: column;

        width: 100%;
        margin-left: auto;
        margin-right: auto;

        gap: 15px;
    }

    .Button {
        max-width: 450px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
    }

    .Input {
        width: 100%;
    }
}
