@import '../../Style/breakpoint.scss';
@import '../../Style/theme.scss';

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80dvh;
    overflow-x: scroll;
}

iframe {
    width: 512px;
    height: 832px;
    border-radius: 20px;
    border: none;
    background-color: $white;

    @media (max-width: $MOBILE_BREAKPOINT) {
        width: 100vw;
        height: 100dvh;
        border-radius: 0;
    }
}

.iframe-wrapper-mobile {
    width: 375px;
    height: 95dvh;
}

.modal {
    z-index: 50;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    max-height: max-content;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100dvh;
    justify-content: center;
    align-items: center;
    background-color: #01010180;
}

.modal-mobile {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 50;
    width: 100vw;
    height: 100dvh;
}

.tryButton {
    margin-right: 8px;
    cursor: pointer;
    background-color: white;
    padding: 16px;
    border-radius: 16px;
    color: green;
    border: 1px green solid;
}
