@import '../../../../Style/theme.scss';

.AuthHomeEmptyView {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;

    .img-empty {
        width: 241px;
        height: 267px;
    }

    .text-body-medium {
        margin-top: 24px;
    }

    .text-body-large-bold {
        text-align: center;
    }

    .wrapper-picture {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        margin-top: 20px;
        border-radius: 100px;
        width: 100px;
        height: 100px;
        background-color: $surface-brand-rest-secondary;

        transition: all 0.3s ease-in-out;

        .little-circle {
            display: flex;
            justify-content: center;
            align-items: center;

            width: 64px;
            height: 64px;
            border-radius: 100px;

            background-color: $surface-brand-rest-primary;
            box-shadow: 0px 4px 6px 0px #166a4740;

            transition: all 0.3s ease-in-out;
        }

        &:hover {
            .little-circle {
                width: 100px;
                height: 100px;

                svg {
                    scale: 1.2;
                    transition: all 0.3s ease-in-out;
                }
            }
        }
    }
}
