.OTPReceiveCode {
    display: flex; 
    position: relative;
    gap: 10px;

    .clearButton {
        position: absolute; 
        top: 0.75rem; 
        font-size: 1.5rem;
        line-height: 2rem; 
        right: -30px;
    }

    .input-otp {
        display: flex; 
        padding: 0.5rem; 
        width: 2.5rem; 
        font-size: 1.5rem;
        line-height: 2rem; 
        text-align: center; 
        border-radius: 10px;
        border: 1px solid #00000033
    }
}