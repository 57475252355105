@import './Style/typographie.scss';
@import './Style/utils.scss';

body {
    margin: 0;

    h1,
    h2,
    h3,
    h4 {
        margin: 0;
        font-weight: normal;
    }
}

bold {
    font-weight: bold;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

html,
body,
#root,
.App {
    height: 100%;
}

a {
    text-decoration: none;
    color: inherit;
}
