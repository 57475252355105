@import '../../Style/theme.scss';
@import '../../Style/typographie.scss';

.GeneralModal {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.modal-overlay {
    background: $surface-grey-background;
    border-radius: 10px;
    box-shadow: 0px 25px 60px -10px rgba(28, 39, 49, 0.12);
    height: 50%;
    inset: 25% 5px;
    margin-left: 15px;
    margin-right: 15px;
    position: fixed;
    top: 25%;
    z-index: 1000;
}

.modal-container {
    position: absolute;
}

.modal-content {
    padding: 20px;
    flex-direction: column;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;
}

.modal-buttons {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    min-width: 100%;
}

.modal-buttons-mobile {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-width: 100%;
}

.modal-title {
    @include headline-medium-mixin-alt;
}
