.Step1 {
    .wrapper-input {
        display: flex;
        flex-direction: column;
        gap: 20px;

        padding: 40px 0;
    }

    .wrapper-password {
        display: flex;
        flex-direction: column;
        gap: 20px;
    
        &.-visible {
            animation: fadeIn 1s ease-in-out forwards;
        }
    }
    
    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
}