.CreateAccountPage {
    display: flex;
    flex-direction: column;
    height: 100%;

    .wrapper-back-icon {
        cursor: pointer;
        width: fit-content;
        margin-top: 14px;
    }

    .step-content {
        display: flex;
        flex-direction: column;

        margin-top: auto;
        margin-bottom: auto;

        .step {
            margin-top: auto;
            margin-bottom: auto;
            width: 100%;
        }

        .PoweredBy {
            margin: 24px 0;
        }
    }
}
