@import '../../../../../Style/breakpoint.scss';
@import '../../../../../Style/theme.scss';

.GalleryDetail {
    width: 100%;
    padding-bottom: 8px;

    .image-container {
        padding: 24px;
        position: relative;
        img {
            display: block;
            width: 100%;
            object-fit: cover;
            border: 1px solid $border-grey;
            border-radius: 10px;
            padding: 0px;
        }
        .trash-button {
            position: absolute;
            text-align: right;
            top: 80%;
            left: 75%;
            cursor: pointer;
        }
    }

    .back-arrow-container {
        margin: 3px 0 40px !important;
    }

    .header {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .buttons {
        display: flex;
        flex-direction: row;
        gap: 10px;
        > Button {
            text-transform: uppercase;

            span {
                font-size: clamp(11px, 1.5vw, 14px) !important;
            }
        }
    }
}
